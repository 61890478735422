import React from 'react';
import * as Styled from './timelineCardStyles';
import { BLOCKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

const TimelineCard = ({ sectionData, index }) => {
  const optionsMainStyle = {
    renderNode: {
      [BLOCKS.HEADING_3]: (node, children) => (
        <Styled.HeaderH3>{children}</Styled.HeaderH3>
      ),

      [BLOCKS.PARAGRAPH]: (node, children) => (
        <Styled.ParaStyle>{children}</Styled.ParaStyle>
      ),
    },
  };
  return (
    <Styled.CardContainer>
      <Styled.IconDiv></Styled.IconDiv>
      <Styled.LeftCol
        $padding={index % 2 === 0 ? '0 80px 0 0' : '0 0 0 80px'}
        $paddingLg={index % 2 === 0 ? '0 50px 0 0' : '0 0 0 50px'}
        xl={{ order: index % 2 !== 0 ? 2 : 1, span: 6 }}
        lg={{ order: index % 2 !== 0 ? 2 : 1, span: 6 }}
        md={{ order: 2 }}
        sm={{ order: 2 }}
        xs={{ order: 2 }}
      >
        <Styled.GreyCard>
          {sectionData?.image?.file?.url && (
            <Styled.ImageRight
              src={sectionData?.image?.file?.url}
            ></Styled.ImageRight>
          )}
          {sectionData?.richText &&
            documentToReactComponents(
              JSON.parse(sectionData?.richText?.raw),
              optionsMainStyle
            )}
        </Styled.GreyCard>
      </Styled.LeftCol>
      <Styled.RightCol
        xl={{ order: index % 2 !== 0 ? 1 : 2 }}
        lg={{ order: 1 }}
        sm={{ order: 1 }}
        xs={{ order: 1 }}
      >
        <Styled.DateContainer $textAlign={index % 2 !== 0 ? 'right' : 'left'}>
          {sectionData?.title}
        </Styled.DateContainer>
      </Styled.RightCol>
    </Styled.CardContainer>
  );
};
export default TimelineCard;
